import moment from 'moment';

export const priceToWord = (price) => {
  var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      handle_tens = function (dgt, prevDgt) {
          return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
          return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      };

  var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
  if (price += "", isNaN(parseInt(price))) str = "";
  else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
          case 0:
              words.push(handle_utlc(digit, nxtDigit, ""));
              break;
          case 1:
              words.push(handle_tens(digit, price[digitIdx + 1]));
              break;
          case 2:
              words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
              break;
          case 3:
              words.push(handle_utlc(digit, nxtDigit, "Thousand"));
              break;
          case 4:
              words.push(handle_tens(digit, price[digitIdx + 1]));
              break;
          case 5:
              words.push(handle_utlc(digit, nxtDigit, "Lakh"));
              break;
          case 6:
              words.push(handle_tens(digit, price[digitIdx + 1]));
              break;
          case 7:
              words.push(handle_utlc(digit, nxtDigit, "Crore"));
              break;
          case 8:
              words.push(handle_tens(digit, price[digitIdx + 1]));
              break;
          case 9:
              words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
              break;
          default:
            break;
      }
      str = words.reverse().join("")
  } else str = "";
  return str
}

export const invoicehtml = (details) => {
    console.log("details>>>>", details);    
    let invoicesHtml = '';
    details.Invoicelrs.map((item, itemIndex) => invoicesHtml += `<tr>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.number}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.date ? moment(item.LRdetail.date).format('DD/MM/YYYY') : ''}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.origin_city.name}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.destination_city.name}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.packageNumber}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.Vehical ? item.LRdetail.Vehical.vehicleNumber : ""}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.LRdetail.Vehical ? item.LRdetail.Vehical.Vehicaltype.vehicalType : ""}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.freight}</td>        
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.detention||"0"}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.pointDelivery||"0"}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.expressCharge||"0"}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.loading+item.unloading||"0"}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.other||"0"}</td>
        <td style="border: 1px solid #000;word-wrap: break-word;">${item.totalAmount}</td>
    </tr>`);

    let taxableHtml = '';
    let taxableSubHtml = '';
    if(details.taxable) {
        taxableHtml = `<div style="border-bottom: 1px solid #000;">
        <div style="float:left; width: 80%; border-right: 1px solid #000;"><h6 style="margin: 5px;"></h6></div>
        <div style="float:left; width: 10%; border-right: 1px solid #000;"><h6 style="margin: 5px;">Total</h6></div>
        <div style="float:left; width: 10%;"><h6 style="margin: 5px;">${details.invoiceTotal}</h6></div>
        <div style="clear:both;"></div>
      </div>`;

      if(details.igs) {
        taxableSubHtml = `<div style="border-bottom: 1px solid #000;">
            <div style="float:left; width: 80%; border-right: 1px solid #000;"><h6 style="margin: 5px;"></h6></div>
            <div style="float:left; width: 10%; border-right: 1px solid #000;"><h6 style="margin: 5px;">IGST ${details.tax}%</h6></div>
            <div style="float:left; width: 10%;"><h6 style="margin: 5px;">${details.taxamount}</h6></div>
            <div style="clear:both;"></div>
        </div>`;
      } else {
        taxableSubHtml = `<div style="border-bottom: 1px solid #000;">
            <div style="float:left; width: 70%; border-right: 1px solid #000;"><h6 style="margin: 5px;"></h6></div>
            <div style="float:left; width: 20%; border-right: 1px solid #000;"><h6 style="margin: 5px;">CGST/SGST ${details.cgst}% * ${details.sgst}%</h6></div>
            <div style="float:left; width: 10%;"><h6 style="margin: 5px;;">${details.taxamount}</h6></div>
            <div style="clear:both;"></div>
        </div>`;
      }
    }
    return `<!DOCTYPE html>
    <html>
      <head>
        <mate charest="utf-8" />
        <title>Invoice</title>
        <style>
          body { font-size: 10px; }
          h2 { font-family: Arial, Helvetica, sans-serif; font-weight: bolder; font-size: 16px;}
          h6 { font-family: Arial, Helvetica, sans-serif; font-weight: normal; font-size: 12px;}
          h4 { font-family: Arial, Helvetica, sans-serif; font-size: 14px;}
        </style>
      </head>
      <body style="border: 1px solid #000; margin: 20px;">
        <div style="text-align: right; border-bottom: 1px solid #000;">
    
          <div style="text-align: center; float: left; width: 20%; padding-top: 20px;">
            <img style="height: 120px;" src="https://elegant-asset.s3.amazonaws.com/invoice_logo.jpeg" />
          </div>
          <div style="float: right; width: 79%; border-left: 1px solid #000">
            <h2 style="margin: 5px;">ELEGANT EXPRESS LOGISTICS PRIVATE LIMITED</h2>
            <h6 style="margin: 5px;">Off No 6, Ankush Plaza, Beside Telephone Exchange, Ajab Nagar, Kranti Chowk, Aurangabad -431005</h6>
            <h6 style="margin: 5px;">Contact details: Cell: 9923525925; Email : rajendralohiya@yahoo.com</h6>
            <h4 style="margin: 5px;">PAN NO- AAGCE0311B</h4>
            <h4 style="margin: 5px;">GST NO -27AAGCE0311B1ZG.</h4>
            <h4 style="margin: 5px;">CIN - U60200MH2020PTC344043</h4>
          </div>
          <div style="clear: both;"></div>
        </div>
        <div>
          <div style="float: left; width: 50%; border-right: 1px solid #000">
            <div><h6 style="margin: 5px;">Bill To Party</h6></div>
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;">${details.Customer.name}</h6></div>
              <div style="clear: both;"></div>
            </div>
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">Address &nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;">${details.Customer.address}</h6></div>
              <div style="clear: both;"></div>
            </div>
            <div>
              <div style="float: left; width: 50%;">
                <div>
                  <div style="float: left;"><h6 style="margin: 5px;">GST No &nbsp;&nbsp;&nbsp;:</h6></div>
                  <div style="float: left;"><h6 style="margin: 5px;">${details.Customer.GSTNumber ? details.Customer.GSTNumber : ""}</h6></div>
                  <div style="clear: both;"></div>
                </div>
                <div>
                  <div style="float: left;"><h6 style="margin: 5px;">State &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
                  <div style="float: left;"><h6 style="margin: 5px;">${details.Customer.City.State.name}</h6></div>
                  <div style="clear: both;"></div>
                </div>
              </div>
              <div style="float: left; width: 50%;">
                <div>
                  <div style="float: left;"><h6 style="margin: 5px;">PAN No &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
                  <div style="float: left;"><h6 style="margin: 5px;">${details.Customer.pan ? details.Customer.pan : ""}</h6></div>
                  <div style="clear: both;"></div>
                </div>
                <div>
                  <div style="float: left;"><h6 style="margin: 5px;">State Code &nbsp;:</h6></div>
                  <div style="float: left;"><h6 style="margin: 5px;">${details.Customer.City.State.id}</h6></div>
                  <div style="clear: both;"></div>
                </div>
              </div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <div style="float: left;">
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">Bill No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;">EELPL/${details.finacialYear}/${details.invoiceNo}</h6></div>
              <div style="clear: both;"></div>
            </div>
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">Bill Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;">${details.invoiceDate ? moment(details.invoiceDate).format('DD/MM/YYYY') : ''}</h6></div>
              <div style="clear: both;"></div>
            </div>
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">SAC Code &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;">${details.sacCode}</h6></div>
              <div style="clear: both;"></div>
            </div>
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">Place Of Supply &nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;"></h6></div>
              <div style="clear: both;"></div>
            </div>
            <div>
              <div style="float: left;"><h6 style="margin: 5px;">State Code &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</h6></div>
              <div style="float: left;"><h6 style="margin: 5px;"></h6></div>
              <div style="clear: both;"></div>
            </div>
          </div>
          <div style="clear: both;"></div>
        </div>
    
        <table style="font-size: 10px; width: 100%; border-collapse: collapse; table-layout: fixed;" cellpadding="3">
          <tr>
            <td style="border: 1px solid #000;word-wrap: break-word;">LR . No.</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">LR . Date</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Origin</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Destination</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Qty</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Vehicle No.</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Vehicle Size</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Freight</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Detention</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">2 Point Delivery</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Express Charge</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Loading / Unloading</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Green Tax / Other</td>
            <td style="border: 1px solid #000;word-wrap: break-word;">Total</td>
          </tr>
          ${invoicesHtml}
        </table>
    
        ${taxableHtml}
    
        ${taxableSubHtml}
    
        <div style="border-bottom: 1px solid #000;">
          <div style="float:left; width: 80%; border-right: 1px solid #000;"><h6 style="margin: 5px;">Rupees in word : ${priceToWord(details.grandTotal)}.</h6></div>
          <div style="float:left; width: 10%; border-right: 1px solid #000;"><h6 style="margin: 5px;">Grand Total</h6></div>
          <div style="float:left; width: 3%;"><h6 style="margin: 5px;">${details.grandTotal}</h6></div>
          <div style="clear:both;"></div>
        </div>
        <div style="border-bottom: 1px solid #000;">
          <div><h6 style="margin: 5px; text-align: center;">${details.typeText}</h6></div>
        </div>
        <div>
          <div style="width: 70%; float: left;">
            <h6 style="margin: 5px; margin-top: 10px; font-weight: bold;">Our Bank details :</h6>
            <h6 style="margin: 5px;">Elegant Express Logistics Pvt. Ltd.</h6>
            <h6 style="margin: 5px;">HDFC BANK LIMITED</h6>
            <h6 style="margin: 5px;">A/C. NO.50200053389884</h6>
            <h6 style="margin: 5px;">IFSC CODE: HDFC0000113. BRANCH CODE 113</h6>
            <h6 style="margin: 5px;">BRANCH-AKASHWANI BRANCH, SHIVANI CHAMBERS, JALNA ROAD, AURANGABAD 431001 (MS).</h6>
          </div>
          <div style="width: 30%; float: right;">
            <h6 style="margin: 5px; margin-top: 10px;">&nbsp;</h6>
            <h6 style="margin: 5px; font-weight: bold;">FOR ELEGANT EXPREESS LOGISTICS PVT.LTD.</h6>
            <h6 style="margin: 5px;">&nbsp;</h6>
            <h6 style="margin: 5px;">&nbsp;</h6>
            <h6 style="margin: 5px; font-weight: bold;">Authorised Signatory</h6>
          </div>
          <div style="clear:both;"></div>
        </div>
      </body>
    </html>`;
};
