import moment from 'moment';

export const lrhtml = (details) => {
    let invoicesHtml = '';
    details.LRInvoices.map((item, itemIndex) => invoicesHtml += `<tr>
        <td style="border: 1px solid #000; word-wrap: break-word;">${itemIndex + 1}</td>
        <td style="border: 1px solid #000; word-wrap: break-word;">${item.invoiceNo}</td>
        <td style="border: 1px solid #000; word-wrap: break-word;">${item.description}</td>
        <td style="border: 1px solid #000; word-wrap: break-word;">${item.weight}</td>
        <td style="border: 1px solid #000; word-wrap: break-word;">${item.amount}</td>
        <td style="border: 1px solid #000; word-wrap: break-word;">${item.eway} </td>
        <td style="border: 1px solid #000; word-wrap: break-word;">${item.validity ? moment(item.validity).format('DD/MM/YYYY') : ''}</td>
    </tr>`)

    let vehicleNumber = details.vehicleNumber;
    let vehicalType = '';
    if(details && details.Vehical && details.Vehical.vehicleNumber) {
        vehicleNumber = details.Vehical.vehicleNumber;
        if(details.Vehical.Vehicaltype && details.Vehical.Vehicaltype.vehicalType) {
            vehicalType = details.Vehical.Vehicaltype.vehicalType;
        }
    }

    let driverNumber = details.contactNumber;
    if(details && details.Driver && details.Driver.contactNumber) {
        driverNumber = details.Driver.contactNumber;
    }

    let driverName = details.driverName;
    if(details && details.Driver && (details.Driver.first_name || details.Driver.last_name)) {
        driverName = `${details.Driver.first_name} ${details.Driver.last_name}`;
    }
     
    return `<!DOCTYPE html>
    <html>
        <head>
            <mate charest="utf-8" />
            <title>LR Details</title>
            <style>
                body {
                    font-size: 10px;
                }
                h2 {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bolder;
                    font-size: 16px;
                }
                h6 {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: normal;
                    font-size: 10px;
                    margin: 5px 0;
                }
                h4 {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 14px;
                }
                .text-bold {
                    font-weight: bold;
                }
            </style>
        </head>
        <body style="border: 1px solid #000; margin: 20px;">
            <div style="text-align: center;border-bottom: 1px solid #000;border-top: 1px solid #000;border-left: 1px solid #000;border-right: 1px solid #000;">
                <div style="text-align: center; float: left; width: 37.2%; padding-top: 20px;">
                    <img style="height: 100px; padding: 0 5px;" src="https://elegant-asset.s3.amazonaws.com/invoice_logo.jpeg" />
                </div>
                <div style="float: left; width: 37%; border-left: 1px solid #000; border-right: 1px solid #000;">
                    <h2 style="margin: 5px;" class="text-bold">ELEGANT EXPRESS LOGISTICS PRIVATE LIMITED</h2>
                    <h6 style="margin: 5px;">Off No 6, Ankush Plaza, Beside Telephone Exchange,</h6>
                    <h6 style="margin: 5px;">Ajab Nagar, Kranti Chowk, Aurangabad -431005</h6>
                    <h6 style="margin: 5px;">Cell: 9923525925; Email : rajendralohiya@yahoo.com</h6>
                    <h6 style="margin: 5px;">GST NO -27AAGCE0311B1ZG.</h6>
                    <h6 style="margin: 5px;"><a href="www.elegantlogistics.in">www.elegantlogistics.in</a></h6>
                </div>
                <div style="float: left; width: 24%;">
                    <h2 style="margin: 5px;">QR Code</h2>
                </div>
                <div style="clear: both;"></div>
            </div>
            <div style="float: left; width: 100%; border: 1px solid #000;">
                <div style="float: left; width: 74%;">
                    <div>
                        <div style="float: left; width: 50%; border-right: 1px solid #000;">
                            <div><h6 style="margin: 5px;" class="text-bold">Sender</h6></div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignor.name}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignor.address}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignee.GST_number}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignor.contact_number}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div style="clear: both;"></div>
                            <div>
                                <h6 style="padding: 5px; border-top: 1px solid #000;" class="text-bold">Receiver</h6>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignee.name}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignee.address}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignee.GST_number}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div>
                                <div style="float: left;"><h6 style="margin: 5px;">${details.consignee.contact_number}</h6></div>
                                <div style="clear: both;"></div>
                            </div>
                            <div style="clear: both;"></div>
                            <div><h6 style="padding: 5px; border-top: 1px solid #000;" class="text-bold">Additional Delivery Point Address:</h6></div>
                            <div style="float: left;"><h6 style="margin: 5px;">${details.additionalAddress ? details.additionalAddress : ''}</h6></div>
                            <div style="clear: both;"></div>
                        </div>
                        <div style="float: left; width: 49.5%;">
                            <div style="float: left; width: 49%; border-left: 1px solid #000; border-right: 1px solid #000;">
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">LR No:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${details.number}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">LR Booking Date:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${details.date ? moment(details.date).format('DD/MM/YYYY') : ''}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Booking Type:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${details.bookingType}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Vehicle Number:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${vehicleNumber}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Vehicle Type:</h6>
                                    </div>
                                    <div>
                                        <h6 style="padding: 0 5px;">${vehicalType}</h6>
                                    </div>
                                </div>
                            </div>
                            <div style="float: left; width: 50%;">
                                <div>
                                    <div>
                                        <h6 style="margin: 5px;" class="text-bold">Origin:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${details.origin_city.name}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Destination:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${details.destination_city.name}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Driver Number:</h6>
                                    </div>
                                    <div style="border-bottom: 1px solid #000;">
                                        <h6 style="padding: 0 5px;">${driverNumber}</h6>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Driver Name:</h6>
                                    </div>
                                    <div>
                                        <h6 style="padding: 0 5px;">${driverName}</h6>
                                    </div>
                                </div>
                            </div>
                            <div style="clear: both;"></div>
                            <div style="border-top: 1px solid #000;">
                                <div>
                                    <div>
                                        <h6 style="padding: 0 5px;" class="text-bold">Remark:</h6>
                                    </div>
                                    <div>
                                        <h6 style="padding: 0 5px;">${details.remarks ? details.remarks : ''}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both;"></div>
                    </div>
                    <table style="float: left; font-size: 10px; width: 100%; border-collapse: collapse; table-layout: fixed;" cellpadding="3">
                        <tr>
                            <td style="border: 1px solid #000; word-wrap: break-word;">Sr no.</td>
                            <td style="border: 1px solid #000; word-wrap: break-word;">Invoice Number</td>
                            <td style="border: 1px solid #000; word-wrap: break-word;">Description of Goods</td>
                            <td style="border: 1px solid #000; word-wrap: break-word;">Weight</td>
                            <td style="border: 1px solid #000; word-wrap: break-word;">T. Amount</td>
                            <td style="border: 1px solid #000; word-wrap: break-word;">E-way Bill Number</td>
                            <td style="border: 1px solid #000; word-wrap: break-word;">Validity Date</td>
                        </tr>
                        ${invoicesHtml}
                    </table>
                    <div style="float: left; width: 100%;">
                        <p style="margin: 5px;">
                            This is consginment copy and doesn’t transfer the ownership of goods to the transporter. Consignor is shipping the goods at his/her own risk. Transporter can't be made liable for any breakage/ damage /leakage to the goods. Subject to Aurangabad Jurisdiction only. Terms & Conditions Apply*
                        </p>
                    </div>
                </div>
                <div style="float: left; width: 25%;">
                    <div style="float: left; border-left: 1px solid #000; width: 100%;">
                        <div style="float: left; width: 100%;">
                            <div>
                                <div>
                                    <h6 style="padding: 0 5px;">${details.copy}</h6>
                                </div>
                            </div>
                            <div style="border-top: 1px solid #000;">
                                <div>
                                    <h6 style="padding: 0 5px;" class="text-bold">Insurance of Goods:</h6>
                                </div>
                                <div style="padding: 0 5px;" >
                                    ${details.insuranceOfGoods ? details.insuranceOfGoods : ''}
                                </div>
                            </div>
                            <div style="border-top: 1px solid #000;">
                                <div>
                                    <h6 style="padding: 0 5px" class="text-bold">Mode of Payment:</h6>
                                </div>
                                <div>
                                    <h6 style="padding: 0 5px">${details.modeOfPayment ? details.modeOfPayment : ''}</h6>
                                </div>
                            </div>
                            <div style="border-top: 1px solid #000;">
                                <div style="float: left; width: 55%; border-right: 1px solid #000;">
                                    <h6 style="padding: 0px 5px; border-bottom: 1px solid #000;" class="text-bold">Particulars</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">Freight</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">Loading/Unloading</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">Extra Point Delivery</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">Other Charges</h6>
                                    <h6 style="padding: 0 5px; border-top: 1px solid #000;" class="text-bold">Total</h6>
                                </div>
                                <div style="float: left; width: 44%;">
                                    <h6 style="padding: 0 5px; border-bottom: 1px solid #000;" class="text-bold">Amount</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">${details.amount}</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">-</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">-</h6>
                                    <h6 style="padding: 0 5px" class="text-bold">-</h6>
                                    <h6 style="padding: 0 5px; border-top: 1px solid #000;" class="text-bold">${details.amount}</h6>
                                </div>
                                <div style="clear: both;"></div>
                            </div>
                            <div style="border-top: 1px solid #000; min-height: 150px;">
                                <div style="padding: 0 5px;">
                                    <h6 class="text-bold">Receiver's Signature and Stamp</h6>
                                </div>
                                <div style="padding: 0 5px; border-top: 1px solid #000;">
                                    <h6 class="text-bold">Delivery Date:</h6>
                                    <h6 class="text-bold">Delivery Time:</h6>
                                </div>
                            </div>
                            <div style="border-top: 1px solid #000;">
                                <div style="padding: 0 5px;">
                                    <h6 class="text-bold">For Elegant Express Logistics Pvt. Ltd.</h6>
                                </div>
                                <div style="padding: 0 5px; margin-top: 50px;">
                                    <h6>Authorised Signatory:</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>`;
};
