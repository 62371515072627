export const API_DOMAIN = 'https://api.elegantlogistics.in';
// export const API_DOMAIN = 'http://elegantapi-env-1.eba-qewqb79q.us-east-1.elasticbeanstalk.com';
// export const API_DOMAIN = 'https://8108-116-74-150-124.ngrok-free.app';
// export const API_DOMAIN = 'http://localhost:5002';
// export const API_DOMAIN = 'http://elegantapi-env-2-uat.us-east-1.elasticbeanstalk.com';
export const S3_PATH = 'https://elegant-asset.s3.amazonaws.com/'
export const S3_PATH_TRIPS = S3_PATH + 'trips/'
export const S3_PATH_INVOICES = S3_PATH + 'invoices/'
export const S3_PATH_LR = S3_PATH + 'lrs/'
export const S3_PATH_TU = S3_PATH + 'trips/details/'
